
import Vue from 'vue'
import BoosterPicture from '../../node_modules/nuxt-booster/runtime/components/BoosterPicture.vue'

export default Vue.extend({
  name: 'Company',
  components: {
    BoosterPicture
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isNewCompany() {
      const publicationDate = this.$dayjs(this.company.publicationStartDate, 'YYYY-MM-DD')
      const today = this.$dayjs()
      const fifteenDaysAgo = today.subtract(15, 'day')

      return publicationDate.isAfter(fifteenDaysAgo) && publicationDate.isBefore(today)
    }
  },
  methods: {
    openLink(link) {
      // this.track()
      // Apri il link in una nuova scheda del browser
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null // Evita che la finestra aperta possa accedere all'opener
        newWindow.focus() // Porta la nuova scheda in primo piano
      } else {
        // Gestisci il blocco dei popup
        alert('I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.')
      }
    },
    track () {
      this.$gtm.push({
        event: 'click_company',
        event_data: {
          company_name: this.company.company.name,
          company_place: this.city,
          open_job_application: this.positions > 0,
          page_url: this.link,
          page_title: document ? document?.title : 'CVing'
        }
      })
    }
  }
})
