
import Vue from 'vue'
import BoosterPicture from '../../node_modules/nuxt-booster/runtime/components/BoosterPicture.vue'
export default Vue.extend({
  name: 'Event',
  components: {
    BoosterPicture
  },
  props: {
    box: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    openLink(link) {
      // this.track()
      // Apri il link in una nuova scheda del browser
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null // Evita che la finestra aperta possa accedere all'opener
        newWindow.focus() // Porta la nuova scheda in primo piano
      } else {
        // Gestisci il blocco dei popup
        alert('I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.')
      }
    },
    track () {
      this.$gtm.push({
        event: 'click_event',
        event_data: {
          event_name: this.title,
          event_url: this.link,
          is_special: false,
          page_url: this.$router.currentRoute.fullPath,
          page_title: this.title
        }
      })
    }
  }
})
